import React from "react"

const chipGreen = {
  backgroundColor: "Green",
  color: "white",
  padding: "1.5% 2%",
  margin: "1%",
  fontSize: "0.8em",
  height: "1.5rem",
  display: "flex",
  alignItems: "center",
  alignSelf: "end",
}

const chipRed = {
  backgroundColor: "red",
  color: "white",
  padding: "1.5% 2%",
  margin: "1%",
  fontSize: "0.8em",
  height: "1.5rem",
  display: "flex",
  alignItems: "center",
}

const chipYellow = {
  backgroundColor: "yellow",
  padding: "1.5% 2%",
  margin: "1%",
  fontSize: "0.8em",
  height: "1.5rem",
  display: "flex",
  alignItems: "center",
}

const chipDefault = {
  backgroundColor: "#eeeeee",
  padding: "1.5% 2%",
  margin: "1%",
  fontSize: "0.8em",
  height: "1.5rem",
  display: "flex",
  alignItems: "center",
}

const Chip = ({ chipText, chipStyle, chipHTML }) => {
  switch (chipStyle) {
    case "green":
      chipStyle = chipGreen
      break
    case "red":
      chipStyle = chipRed
      break
    case "yellow":
      chipStyle = chipYellow
      break
    default:
      chipStyle = chipDefault
  }
  return (
    <>
      <div style={chipStyle}>
        <span dangerouslySetInnerHTML={{ __html: chipHTML }}></span>
        {chipText}
      </div>
    </>
  )
}

export default Chip

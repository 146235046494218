import React from "react"
import style from "./Footer.module.css"

class Footer extends React.Component {
  render() {
    return (
      <div className={style.section}>
        <div className={style.footerSection}>
          <address>
            <i className="fas fa-map-marker-alt"></i>
            <br />
            Clay House Mill <br />
            Clay House Lane <br />
            Greetland <br />
            HX4 8AW
          </address>
        </div>
        <div className={style.footerSection}>
          <img
            src="https://htdirect.blob.core.windows.net/static/FAIB.jpg"
            alt=""
          />
        </div>
        <div className={style.footerSection}>
          <img
            src="https://htdirect.blob.core.windows.net/static/fofato.png"
            alt=""
          />
        </div>
        <div className={style.footerSection}>
          <img
            src="https://htdirect.blob.core.windows.net/static/chrt.jpg"
            alt=""
          />
        </div>
        <div className={style.footerSection}>
          <div>
            <i className="fas fa-phone"></i> 01422 646066
          </div>
          <div>
            <i className="fas fa-phone"></i> 07778177582
          </div>
          <div>
            <i className="fas fa-envelope"></i> support@htdirect.co.uk
          </div>
        </div>
      </div>
    )
  }
}

export default Footer

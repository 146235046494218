import React from "react"
import style from "./DividerBlock.module.css"

const DividerBlock = () => {
  return (
    <>
      <div className={style.divider}>
        <div className={style.dividerBG}> </div>
        <div className={style.headline_text}>
          FIRST CLASS FIRST-AID TRAINING & EVENT COVER
        </div>
        <div className={style.innerHtml}>
          <div>
            <h3>
              <i className="fas fa-phone"></i> 01422 646 066
            </h3>
            <h3>
              <i className="fas fa-phone"></i> 07778 177 582
            </h3>
            <h3>
              <i className="fas fa-envelope"></i> support@htdirect.co.uk
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default DividerBlock

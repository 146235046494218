import React from "react"
import style from "./NavigationBar.module.css"
import { Link } from "gatsby"

const NavigationBar = () => {
  return (
    <>
      <nav>
        <div className={style.navMenu}>
          <div className={style.navLinks}>
            <Link to="/#courses">Courses</Link>
            <Link to="/#events">Events</Link>
            <Link to="/#supplies">Supplies</Link>
            <Link to="/#contact">Contact</Link>
          </div>
        </div>
      </nav>
      <div className={style.navOffset}></div>
    </>
  )
}

export default NavigationBar

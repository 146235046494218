import React, { useState } from "react"
import Chip from "../shared/Chip"

const Course = ({ img, title, courseLength, price, summary, description }) => {
  const [modalOn, setModalOn] = useState(false)
  return (
    <div className="card">
      <img src={img} alt="" />
      <div className="card_body">
        <h4>{title}</h4>
        <div className="chips">
          <Chip
            chipStyle="defualt"
            chipHTML='<i class="far fa-hourglass"></i>'
            chipText={courseLength}
          />
          <Chip chipStyle="green" chipText="Dates Available" />
          <Chip
            chipStyle="defualt"
            chipHTML='<i class="fas fa-user"></i><i class="fas fa-pound-sign"></i>'
            chipText={price + " per person"}
          />
        </div>
        <p className="summary">{summary}</p>

        {modalOn ? (
          <>
            <button className="button" onClick={() => setModalOn(false)}>
              See less <i className="fas fa-chevron-up"></i>
            </button>
            <div
              class="mainDescription"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </>
        ) : (
          <button className="button" onClick={() => setModalOn(true)}>
            See more <i className="fas fa-chevron-down"></i>
          </button>
        )}
      </div>
    </div>
  )
}

export default Course

import React from "react"
import Chip from "./shared/Chip"

const EventCover = () => {
  return (
    <div id="events" className="section bg_green ">
      <h1 className="text_white"> EVENTS COVER</h1>

      <div className="container">
        <div className="eventWrapper">
          <div className="event_img">
            <img
              src="https://htdirect.blob.core.windows.net/static/EventCoverImg.jpg"
              alt="0"
            />
          </div>
          <div className="event_card">
            <div className="header">
              HT Direct can support all your events first-aid needs
            </div>
            <div className="section1">
              <p>
                Whether it’s a <b>multiple-sited large event</b> over several
                days or a <b>small local community event</b>, we can help
                support you.
              </p>
              <p className="section2">
                HT Direct first aiders are trained to resuscitate and stabilise
                patients with heart attacks or injuries until the emergency
                services arrive.
              </p>

              <p className="section3">
                <small>We have recently covered events such as:</small>
              </p>
            </div>
            <ul className="chips">
              <Chip chipText="Music Concerts and Festivals" />
              <Chip chipText="Corporate Events and Parties" />
              <Chip chipText="Equestrian Events" />
              <Chip chipText="Carnivals" />
              <Chip chipText="Boxing" />
              <Chip chipText="Firework Events" />
              <Chip chipText="Fun Days" />
              <Chip chipText="Fetes" />
              <Chip chipText="Football and Rugby Matches" />
              <Chip chipText="Sport Days" />
              <Chip chipText="Charity Events and Walks" />
            </ul>

            <div className="footer">
              Lives could be saved by having first aid cover for your event.
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_info">
        <p>
          Cover can be provided for an hour, or up to several days if required.
          Get in touch for a quote.
        </p>
      </div>
      <a href="#contact">
        <button>Enquire</button>
      </a>
    </div>
  )
}

export default EventCover

import Course from "./Course"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

const ComponentName = () => (
  <StaticQuery
    query={graphql`
      {
        allCoursesJson(sort: { fields: id }) {
          nodes {
            description
            imgSrc
            lengthOfCourse
            name
            noOfCourses
            pricePerPerson
            id
            summary
          }
        }
      }
    `}
    render={data => {
      return (
        <div id="courses" className="section">
          <h1>COURSES</h1>
          <div className="section_summary">
            <p>
              We understand that your time is precious, so instead of occupying
              valuable time locating and travelling to a training centre of our
              choice, we provide training at the location of your choice.
              Whether you have a dedicated training area or not, just let us
              know where is most convenient for you and we will do the rest.{" "}
              <br /> <br /> We provide a comprehensive service that makes use of
              the latest teaching techniques, interactive learning and practical
              tasks, together with our own specialised training equipment. All
              we need to know is which course you are interested in and the
              number of attendees.
            </p>
          </div>
          {display(data)}
        </div>
      )
    }}
  ></StaticQuery>
)

const display = data => {
  if (data.length === 0) {
    return (
      <h2>Please give us a call for information on our current courses.</h2>
    )
  } else {
    return <div className="grid">{MakeCourseComponents(data)}</div>
  }
}

const MakeCourseComponents = data => {
  var courses = []
  if (data !== undefined && data !== null) {
    data.allCoursesJson.nodes.forEach(course => {
      courses.push(
        <Course
          key={course.id}
          img={course.imgSrc}
          title={course.name}
          subtitle={course.subtitle}
          summary={course.summary}
          slug={course.id}
          price={course.pricePerPerson}
          courseLength={course.lengthOfCourse}
          NoAvailable={course.noOfCourses}
          description={course.description}
        />
      )
    })
  }
  return courses
}

export default ComponentName

export const code = () =>
  "W8jkv16BnI5nS4LcsUqyCNWzJUeBpLwPIcmtYfTvofkbrih98WA7pg=="

export const generateEndpoint = endpoint =>
  `${process.env.GATSBY_API_BASE_PATH}${endpoint}?code=${process.env.GATSBY_API_KEY}`

export const logError = () => {
  if (
    process.env.ENVIRONMENT === "development" ||
    process.env.ENVIRONMENT === "staging"
  ) {
    return console.log("Error calling API")
  } else {
    return null
  }
}

export const errorMessageString =
  "We are currently experiencing an issue with our database. Please bear with us :-("

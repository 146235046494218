import ContactForm from "../components/ContactForm"
import CourseDates from "../components/CourseDates/CourseDates"
import DividerBlock from "../components/DividerBlock/DividerBlock"
import EventCover from "../components/EventCover"
import FirstAidCourseGrid from "../components/FirstAidCourses/CourseGrid"
import Footer from "../components/Footer/Footer"
import Hero from "../components/Hero/Hero"
import NavigationBar from "../components/NavigationBar/NavigationBar"
import React from "react"
import SEO from "../components/shared/seo"
import ShopGrid from "../components/Shop/ShopGrid"
import WhereToFindUs from "../components/WhereToFindUs/WhereToFindUs"

export default function Home() {
  return (
    <>
      <SEO>
        <NavigationBar />
        <Hero />
        <DividerBlock />
        <FirstAidCourseGrid />
        <CourseDates />
        <EventCover />
        <ShopGrid />
        <ContactForm />
        <WhereToFindUs />
        <Footer />
      </SEO>
    </>
  )
}

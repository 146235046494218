import React from "react"
import style from "./Hero.module.css"
import cx from "classnames"
import logo from "./HTLogo_FINAL.svg"

const Hero = () => {
  return (
    <>
      <div className={style.quarters}>
        <div className={cx(style.quarter, style.bg1)}></div>
        <div className={cx(style.quarter, style.bg2)}></div>
        <div className={cx(style.quarter, style.bg2)}></div>
        <div className={cx(style.quarter, style.bg1)}></div>
        <img src={logo} className={style.logo} alt="HT direct logo" />
      </div>
    </>
  )
}

export default Hero

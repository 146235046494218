import React from "react"
import ShopItem from "./ShopItem"
import { StaticQuery, graphql } from "gatsby"

const ShopGrid = () => (
  <StaticQuery
    query={graphql`
      {
        allShopJson {
          nodes {
            name
            description
            imgSource
            id
            quantityInStock
            costPerItem
          }
        }
      }
    `}
    render={data => {
      return (
        <div id="supplies" className="section">
          <h1>FIRST-AID SUPPLIES</h1>
          <div className="section_summary">
            <p>
              We pride ourselves on being up to date regarding current safety
              policy and guidelines. This means that from first aid kits right
              through to specialist paediatric equipment, you can be assured
              that the equipment we provide, is always dependable and meets all
              the current safety standards.
            </p>
          </div>
          <div>{display(data)}</div>
        </div>
      )
    }}
  ></StaticQuery>
)

const display = data => {
  if (data.length === 0) {
    return (
      <h2>Please give us a call for information on our current courses.</h2>
    )
  } else {
    return <div className="grid">{MakeShopItemComponents(data)}</div>
  }
}

const MakeShopItemComponents = data => {
  var shopItems = []
  data.allShopJson.nodes.forEach(item => {
    shopItems.push(
      <ShopItem
        key={item.id}
        img={item.imgSource}
        title={item.name}
        price={item.costPerItem}
        stock={item.quantityInStock}
        description={item.description}
      />
    )
  })
  return shopItems
}

export default ShopGrid

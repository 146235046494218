import React, { useState } from "react"
import axios from "axios"
import { generateEndpoint, logError } from "./shared/Shared"

const EnquiryForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [enquiry, setEnquiry] = useState("")

  return (
    <div id="contact" className="section contact">
      <div className="contact_form_wrapper">
        <div className="contactForm">
          <h1>GET IN TOUCH</h1>
          <h3>
            <i className="fas fa-phone"></i> 01422 646 066
          </h3>
          <h3>
            <i className="fas fa-phone"></i> 07778 177 582
          </h3>
          <h3>
            <i className="fas fa-envelope"></i> support@htdirect.co.uk
          </h3>
          <hr />
          {Form(
            submitted,
            setSubmitted,
            setName,
            setEmail,
            setEnquiry,
            name,
            email,
            enquiry
          )}
        </div>
      </div>
    </div>
  )
}

const Form = (
  submitted,
  setSubmitted,
  setName,
  setEmail,
  setEnquiry,
  name,
  email,
  enquiry
) => {
  if (submitted) {
    return (
      <div class="submitted">
        <h2>Thanks!</h2>
        <h2>Your message has been submitted and someone will be in touch.</h2>
      </div>
    )
  } else {
    return (
      <>
        <h2>Message us</h2>
        <form>
          <label id="nameLabel" htmlFor="name">
            Name:
            <input
              id="name"
              name="name"
              type="text"
              required
              placeholder="Your name"
              onChange={e => setName(e.target.value)}
            />
          </label>
          <label id="emailLabel" htmlFor="email">
            Email:
            <input
              id="email"
              name="email"
              type="email"
              required
              placeholder="Your email address"
              onChange={e => setEmail(e.target.value)}
            />
          </label>
          <label id="enquiryLabel" htmlFor="enquiry">
            Enquiry:
            <textarea
              id="enquiry"
              name="enquiry"
              cols="30"
              rows="10"
              onChange={e => setEnquiry(e.target.value)}
            ></textarea>
          </label>
          <button
            type="button"
            onClick={() => SendEnquiry(name, email, enquiry, setSubmitted)}
          >
            Send
          </button>
        </form>
      </>
    )
  }
}

const SendEnquiry = (name, email, enquiry, setSubmitted) => {
  axios
    .post(generateEndpoint("EnquiryForm"), { name, email, enquiry })
    .catch(e => {
      logError(e)
    })
    .then(res => {
      if (res !== undefined) {
        console.log(res)
        console.log(res.data)
        setSubmitted(true)
      } else {
        window.alert(
          "Sorry, somthing went wrong :-/. Try again later or give us a call"
        )
      }
    })
}

export default EnquiryForm

import React from "react"
import Iframe from "react-iframe"

const WhereToFindUs = () => (
  <div className="section">
    <h1>Where to find us</h1>
    <p>HT Direct is located at Clay House Mill in Greetland</p>
    <p>There is ample parking in the car park.</p>
    <div className="container">
      <Iframe
        width="425"
        height="350"
        frameborder="0"
        scrolling="no"
        src="https://www.openstreetmap.org/export/embed.html?bbox=-1.859564781188965%2C53.68825732200978%2C-1.8525588512420657%2C53.69100507107084&amp;layer=mapnik&amp;marker=53.6896328072497%2C-1.8560618162155151"
        style={{ margin: "auto" }}
      ></Iframe>
    </div>
  </div>
)

export default WhereToFindUs

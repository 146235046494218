import React from "react"
import Chip from "../shared/Chip"

const ShopItem = ({ img, title, stock, description }) => {
  return (
    <>
      <div>
        <img src={img} alt="" />
        <div className="card_body">
          <h4>{title}</h4>
          <div className="chips">
            {stock === 0 ? (
              <Chip chipStyle="red" chipText="out of stock" />
            ) : (
              <Chip chipStyle="green" chipText="in stock" />
            )}
            <Chip chipStyle="defualt" chipText="call for price" />
          </div>
          <p className="summary">{description}</p>
          <a href="#contact">
            <button>Call to order</button>
          </a>
        </div>
      </div>
    </>
  )
}

export default ShopItem

import Coursedate from "./Coursedate"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

const toMonth = monthNumber => {
  switch (monthNumber) {
    case "0":
      return "January"
    case "1":
      return "February"
    case "2":
      return "March"
    case "3":
      return "April"
    case "4":
      return "May"
    case "5":
      return "June"
    case "6":
      return "July"
    case "7":
      return "August"
    case "8":
      return "September"
    case "9":
      return "October"
    case "10":
      return "November"
    case "11":
      return "December"
    default:
      break
  }
}

const groupByDate = function (data, key) {
  return data.reduce(function (storage, item) {
    var date = new Date(item[key])
    var month = date.getMonth()
    var group = month
    storage[group] = storage[group] || []
    storage[group].push(item)
    return storage
  }, {})
}

var sortByDateOldestFirst = (a, b) => {
  if (a.date > b.date) return 1
  if (a.date < b.date) return -1
  return 0
}

const CourseDates = () => (
  <StaticQuery
    query={graphql`
      {
        allCoursesJson {
          nodes {
            name
            id
          }
        }
        allCoursedatesJson {
          nodes {
            courseId
            date
            id
            venue
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <div id="courseDates" className="section bg-white coursedates">
            <h1>UPCOMING DATES</h1>
            <div className="section_summary">
              <p>
                If you are interested in a date not currently shown, <br />
                we may be able to set something up for you. Please give us a
                call.
              </p>
            </div>
            <div>{MakeCoursedateComponents(data)}</div>
          </div>
        </>
      )
    }}
  ></StaticQuery>
)

const MakeCoursedateComponents = data => {
  // sort
  var components = data.allCoursedatesJson.nodes.sort(sortByDateOldestFirst)
  // filter
  components = components.filter(course => new Date(course.date) > Date.now())
  // group
  var el = []
  var byMonth = groupByDate(components, "date")
  for (var month in byMonth) {
    el.push(
      <div>
        <h3>{toMonth(month)}</h3>
        <div className="grid">
          {byMonth[month].map(course => (
            <Coursedate
              date={course.date}
              course={getCoursenameFromId(
                data.allCoursesJson.nodes,
                course.courseId
              )}
              venue={course.venue}
            />
          ))}
        </div>
      </div>
    )
  }
  return <div>{el}</div>
}

const getCoursenameFromId = (coursenames, courseId) => {
  var coursename = ""
  var course = coursenames.find(x => x.id === courseId)
  if (course) {
    coursename = course.name
  } else coursename = ""
  return coursename
}

export default CourseDates
